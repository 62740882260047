<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SECTION_RESULT_DETAILS"
      tooltip-title="SECTION_RESULT_DETAILS"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="resultDetails" custom-classes="flex-col" />
      <div>
        <Heading heading="Students" heading-size="pb-0 text-xl" />
        <div v-if="isEmpty(resultList)">
          <NoRecordFound class="mt-28" />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(result, index) in resultList" :key="index">
              <TD>
                {{ result.subject_result.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ result.subject_result.subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ result.subject_result.total_marks || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ result.subject_result.passing_percentage || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
        <Pagination
          v-if="resultCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
          v-show="!isLoading"
          :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
          :max-range="paginationCounts(resultCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
          @filterRecord="filteredRecords"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import { toPascalCase } from '@utils/generalUtil'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import isEmpty from 'lodash/isEmpty'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'
export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
  },

  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      tableHeaders: ['Subject Result', 'Subject', 'Total Marks', 'Passing Percentage'],
      isLoading: false,
      resultDetails: {},
      currentSelectedSubject: null,
      resultCounts: 0,
      resultList: [],
    }
  },
  page: {
    title: 'Exam Subject Result',
  },
  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope']),
    currentResultId() {
      return this.$route.params.id
    },
  },
  watch: {
    currentClassScope: {
      handler() {
        this.$router.push({ name: 'subject-result' })
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.$router.push({ name: 'subject-result' })
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredRecords()
  },
  methods: {
    isEmpty,
    paginationCounts,
    toPascalCase,

    async filteredRecords(range) {
      this.isLoading = true
      let [response, error] = await this.getSectionExamDetails(this.currentResultId)
      this.resultDetails = this.getDetails(response.data)
      this.resultList = response.data.section_result_subjects
      this.isLoading = false
    },
    getDetails(details) {
      return {
        title: details.title,
        desciption: details.description,
        gradingStructure: details.non_gpa_grading.title,
        minimumPassingSubjects: details.min_passing_subjects,
        totalMarks: details.total_marks,
        generatedAt:
          details.generated_at &&
          this.$filters.formattedDate(details.generated_at, DATE_TIME_CONSTANT.DATE_TIME_12),
        publishedAt:
          details.published_at &&
          this.$filters.formattedDate(details.published_at, DATE_TIME_CONSTANT.DATE_TIME_12),
      }
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', ['getSectionExamDetails']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
  .timeBg {
    background-color: var(--bg-white);
  }
}
</style>
